@use "sass:map";

html {
  position: relative;
  min-height: 100%;
}

body {
  min-height: 100%;
  margin: 0;
  padding: 0 0 $header-height 0;
  background-color: $color-body-bg;
  font-family: $font-standard;
  @if $debug {
    border-width: 5px 0 0 0;
    border-style: solid;
    @include media-map-up(border-color, blue, green, yellow, orange, red);
  }
}

address {
  white-space: pre;
}

section {
  padding: 1rem 0;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font-title;
  font-weight: lighter;
}

h1, h2 {
  clear: both;
  a {
    text-decoration: none;
  }
}

h2 {
  margin: 0 auto;
  padding: 0;
  line-height: 100px;
  font-size: 3rem;
}

ul {
  list-style-type: circle;
  padding-left: 1rem;
}

section {
  position: relative;
  display: block;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  > div, > article {
    @extend .container-lg;
    max-width: map.get($container-max-widths, 'xl');
    margin: 0 auto;
    text-align: left;
  }
}
