body#index {
  section#tagline {
    @include media-map-up(padding-top, 2rem, 2.5rem, 3rem, 3.5rem, 4rem);
    color: $color-one-lead;
    > div {
      > div {
        blockquote {
          @include media-map(font-size, 1.8rem, 1.5rem, 2rem, 2.5rem, 3rem, 3.6rem);
          @include media-map(padding-left, 20px, 40px, 60px, 80px, 100px, 100px);
          line-height: 1.2em;
          strong {

          }
        }
      }
    }
  }
  section#services {
    padding-bottom: 6rem;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' preserveAspectRatio='none'%3E %3Cpolygon points='0,0 50,100 0,100' style='fill:%232BB9B1%3B' /%3E %3Cpolygon points='100,0 50,100 100,100' style='fill:%232BB9B1%3B' /%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100% 4rem;
    > div {
      @extend .container;
      > div {
        @include make-row();
        article {
          @extend .container;
          position: relative;
          @include make-col(12);
          @include media-breakpoint-up(sm) {
            clear: both;
            margin-bottom: 1rem;
            min-height: 115px;
          }
          @include media-breakpoint-up(md) { @include make-col(4); }
          > div, > a {
            @include make-row();
            position: relative;
            margin: 0 auto;
            text-decoration: none;
            text-align: center;
            img {
              @include make-col(12);
              position: relative;
              max-width: 300px;
              margin: 0 auto;
              @include media-breakpoint-up(sm) {
                @include make-col(4);
                position: absolute;
              }
              @include media-breakpoint-up(md) {
                @include make-col(12);
                position: relative;
              }
            }
            h2 {
              @include make-col(12);
              line-height: 1em;
              margin: 0 auto;
              padding-bottom: .5rem;
              position: relative;
              color: $color-one-lead;
              @include media-map(text-align, left, left, center, center, center, center);
              @include media-map(font-size, 2rem, 1.5rem, 1.3rem, 1.7rem, 1.9rem, 2.1rem);
              @include media-map(padding-left, 0, 10px, 0, 0, 0, 0);
              @include media-breakpoint-up(sm) {
                @include make-col(8);
                @include make-col-offset(4);
              }
              @include media-breakpoint-up(md) {
                @include make-col(12);
                @include make-col-offset(0);
              }
            }
            p {
              @include media-map(font-size, 1rem, 1rem, 0.9rem, 1rem, 1.1rem, 1.1rem);
              @include media-map(text-align, left, left, center, center, justify, justify);
              @include media-map(padding-left, 0, 10px, 0, 0, 0, 0);
              @include make-col(12);
              margin-bottom: 0;
              color: #606060;
              @include media-breakpoint-up(sm) {
                @include make-col(8);
                @include make-col-offset(4);
              }
              @include media-breakpoint-up(md) {
                @include make-col(12);
                @include make-col-offset(0);
              }
              strong {
                color: #000000;
              }
            }
          }
          a:hover {
            h2 {
              color: $color-two-title;
            }
            p {
              color: #000000;
            }
          }
        }
        article:nth-child(even) {
          > div, > a {
            img {
              @include media-breakpoint-up(md) { order: 3; }
            }
          }
        }
      }
    }
  }

  section#proposal {
    @include media-map(padding-top, 2rem, 2rem, 2.5rem, 3rem, 3.5rem, 4rem);
    margin-bottom: 0;
    background-color: $color-one-lead;
    color: #F0F0F0;
    text-shadow: 1px 1px 2px #909090;
    > div {
      > div {
        blockquote {
          @include media-map(font-size, 1.8rem, 1.5rem, 2rem, 2.5rem, 3rem, 3.6rem);
          @include media-map(padding-left, 20px, 40px, 60px, 80px, 100px, 100px);
          margin: 0 auto;
          line-height: 1.5em;
          strong {
            @include media-map(font-size, 4rem, 4rem, 4rem, 5rem, 6rem, 7.5rem);
          }
        }
      }
    }
  }

  section#applications {
    color: #f0f0f0;
    background-color: $color-one-lead;
    > div {
      @extend .container;
      > div {
        @include make-row();
        article {
          @extend .container;
          position: relative;
          @include make-col(12);
          @include media-breakpoint-up(sm) { @include make-col(6); }
          @include media-breakpoint-up(md) { @include make-col(3); }
          > div, > a {
            margin: 0 auto;
            text-decoration: none;
            text-align: center;
            h2 {
              line-height: 1em;
              margin: 0 auto;
              padding-bottom: 1rem;
              position: relative;
              span {
                display: block;
              }
              .far, .fas {
                display: block;
                margin: 0 auto;
              }
              @include media-map(text-align, left, left, center, center, center, center);
              text-shadow: 1px 1px 1px #909090;
              color: #f0f0f0;
              .action {
                @include media-map(font-size, 1.4rem, 1.1rem, 1.4rem, 1.4rem, 1.5rem, 1.6rem);
                line-height: 1em;
               }
              .result {
                @include media-map(font-size, 1rem, 0.8rem, 1rem, 1rem, 1rem, 1rem);
                line-height: 1em;
              }
              .far, .fas {
                color: #f0f0f0;
                text-shadow: 1px 1px 1px #909090;
                @include media-map(margin-right, 15px, 10px, auto, auto, auto, auto);
                @include media-map(font-size, 2.5rem, 1.5rem, 3rem, 2.5rem, 3rem, 4rem);
                @include media-map(line-height, 2.5rem, 2.5rem, 3rem, 3rem, 4rem, 5rem);
                @include media-map(float, left, left, none, none, none, none);
              }
            }
            p {
              @include media-map(font-size, 1rem, 1rem, 0.9rem, 1rem, 1.1rem, 1.1rem);
              @include media-map(text-align, left, left, center, center, justify, justify);
              @include make-col(12);
              margin-bottom: 0;
              color: #606060;
              @include media-breakpoint-up(sm) {
                @include make-col(8);
                @include make-col-offset(4);
              }
              @include media-breakpoint-up(md) {
                @include make-col(12);
                @include make-col-offset(0);
              }
              strong {
                color: #000000;
              }
            }
          }
          a:hover {
            h2 {
              color: $color-two-title;
            }
            p {
              color: #000000;
            }
          }
        }
      }
    }
  }

  section#activity {
    @include media-map-up(padding-top, 2rem, 2.5rem, 3rem, 3.5rem, 4rem);
    padding-bottom: 0;
    background-color: $color-two-lead;
    color: $color-two-title;
    text-shadow: 1px 1px 2px $color-two-shadow;
    > div {
      > div {
        @include make-row();
        article {
          @include make-col-ready();
          @include make-col(12);
          > div {
            h2 {
              line-height: 1rem;
              margin: 0 auto;
	      padding-bottom: .5rem;
	      span {
	        display: block;
	      }
              .far, .fas {
	        display: block;
		margin: 0 auto;
              }
            }
            p {
              @include media-map-up(font-size, 0.9rem, 0.9rem, 0.9rem, .9rem, .9rem);
            }
          }
        }
      }
    }
  }
  section#contact {
    background-color: #ffffff;
    padding-top: 5rem;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' preserveAspectRatio='none'%3E %3Cpolygon points='0,0 50,100 100,0' style='fill:%232BB9B1%3B' /%3E %3C/svg%3E");
    background-repeat: no-repeat;
    background-position: top;
    background-size: 100% 4rem;
    color: #606060;
    a {
      color: #606060;
      text-decoration: none;
      border-style: dotted;
      border-color: #606060;
      border-width: 0 0 1px 0;
      &:hover {
        color: #000000;
        border-style: solid;
        border-color: #303030;
      }
    }
    > div {
      > div {
        article {
          @include make-row();
          h2 {
            @include make-col-ready();
            @include make-col(12);
            @include media-map-up(font-size, 2rem, 2rem, 2rem, 2.5rem, 3rem);
          }
          div.meet {
            @include make-col-ready(); 
            @include media-breakpoint-up(md) { @include make-col(6); }
            font-size: 0.9rem;
          }
          div.call {
            @include make-col-ready(); 
            @include media-breakpoint-up(md) { @include make-col(6); }
            img {
              @include media-breakpoint-up(md) { float: left; }
              border-width: 0px;
              border-color: #000;
              border-style: solid;
              margin: 0 20px 30px 0;
              width: 150px;
              box-shadow: 1px 1px 3px #606060;
            }
            h3 {
              @include media-map-up(font-size, 1.2rem, 1.3rem, 1.4rem, 1.5rem, 1.5rem);
              color: #000000;
            }
            p {
              margin: 0 auto 0.5rem auto;
            }
          }
        }
      }
    }
  }
}
