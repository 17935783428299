@use "sass:map";

header {
  position: relative;
  display: block;
  width: 100%;
  top: 0;
  border: 0 solid transparent;
  background-color: $primary;
  margin: 0 auto;
  text-align: center;
  div {
    @extend .container-lg;
    max-width: map.get($container-max-widths, 'xl');
    text-align: left;
    nav {
      @extend .navbar;
      @extend .navbar-expand-md;
      @extend .navbar-light;
      padding: 0;
      border-width: 0;
      background-color: transparent;
      font-family: $font-title;
      div {
        @extend .container-fluid;
        #logo {
          @extend .navbar-brand;
          height: 100%;
          padding: 0;
          margin: 0;
          color: rgba(#fff, 0.7);
          &:hover {
            color: #fff;
          }
          .brand {
            line-height: 25px;
            font-size: 24px;
          }
          .punchline {
            display: block;
            line-height: 15px;
            margin: 0;
            padding: 0;
            font-family: $font-title;
            font-weight: lighter;
            font-size: 15px;
            clear: both;
            color: rgba(#fff, 0.5);
          }
        } 
        .navbar-toggler {
          line-height: $header-height;
          font-size: 2rem;
          color: rgba(#fff, 0.7);
          border-width: 0;
          border-color: transparent;
          outline-width: 0;
          box-shadow: 0 0 0 0;
          &:hover {
            color: #fff;
            border-width: 0;
            border-color: transparent;
            outline-width: 0;
            box-shadow: 0 0 0 0;
          }
          &:focus {
            border-width: 0;
            border-color: transparent;
            outline-width: 0;
            box-shadow: 0 0 0 0;
          }
        }
        #navbarLinks {
          @extend .collapse;
          @extend .navbar-collapse;
          @extend .justify-content-end;
          height: 100%;
          > ul {
            @extend .navbar-nav;
            height: 100%;
            > li {
              @extend .nav-item;
              height: 100%;
              > a {
                height: 100%;
                @extend .nav-link;
                @include media-map-up(padding, 0 2px, 0 3px, 0 5px, 0 10px, 0 20px);
                @include media-map-up(font-size, 1em, 1em, 0.9em, 1.1em, 1.2em);
                background-color: map-get($theme-colors, 'primary');
                color: rgba(#fff, 0.7);
                line-height: $header-height;
                &:hover, &:focus {

                  color: #fff;
                  background-color: map-get($theme-colors, 'warning');
                }
              }
              &.active {
                > a {
                  background-color: #fff;
                  color: map-get($theme-colors, 'primary');
                  &:hover, &:focus {
                    background-color: #fff;
                    color: map-get($theme-colors, 'primary');
                  }
                }
              }
            }
          }
          @include media-breakpoint-down(md) {
            &.show {
              height: auto;
              ul {
                height: auto;
                li {
                  height: auto;
                  a {
                    height: auto;
                    line-height: 1.5em;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
