/* Colors. */

// Custom default values for colors.
// Variables are then used in Bootstrap.
$primary:       #2BB9B1;
$secondary:     #859996;
$success:       #36AB45;
$info:          #5386E4;
$warning:       #F39237;
$danger:        #B80C09;
$light:         #DEE3E2;
$dark:          #262C2B;

$color-body-bg: #ffffff;

$color-one-lead: #2BB9B1;
$color-one-title: #ffffff;
$color-one-text: #fafafa;
$color-one-shadow: #247356;

$color-two-lead: #ffffff;
$color-two-title: #ff9900;
$color-two-text: rgba(#000, 0.9);
$color-two-shadow: rgba(#000, .1);

$color-three-lead: #2bba6b;
$color-three-text: #ffffff;
$color-three-shadow: #176339;

