@font-face {
    font-family: 'opensansregular';
    src: url('../fonts/opensans/opensans-regular-webfont.eot');
    src: url('../fonts/opensans/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/opensans/opensans-regular-webfont.woff') format('woff'),
         url('../fonts/opensans/opensans-regular-webfont.ttf') format('truetype'),
         url('../fonts/opensans/opensans-regular-webfont.svg#open_sanscondensed_light') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'quicksand';
    src: url('../fonts/quicksand/quicksand-regular-webfont.eot');
    src: url('../fonts/quicksand/quicksand-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/quicksand/quicksand-regular-webfont.woff2') format('woff2'),
         url('../fonts/quicksand/quicksand-regular-webfont.woff') format('woff'),
         url('../fonts/quicksand/quicksand-regular-webfont.ttf') format('truetype'),
         url('../fonts/quicksand/quicksand-regular-webfont.svg#quicksandregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'inconsolata';
    src: url('../fonts/inconsolata/Inconsolata-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

// Bootstrap
$font-family-base: 'opensansregular', sans-serif;
$font-family-code: 'inconsolata', monospace;
$headings-font-family: 'quicksand', sans-serif;
$display-font-family: 'quicksand', sans-serif;

// FontAwesome
$fa-font-path: "npm:@fortawesome/fontawesome-free/webfonts";
@import "@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "@fortawesome/fontawesome-free/scss/solid.scss";
@import "@fortawesome/fontawesome-free/scss/regular.scss";
