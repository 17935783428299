body#articles {
  section {
    @include media-map-up(padding-top, 2rem, 2rem, 2rem, 2rem, 2rem);
    position: relative;
    h1 {
      @include media-map-up(font-size, 2.5rem, 2.7rem, 2.8rem, 2.9rem, 3rem);
      color: $color-one-lead;
    }
    article {
      clear: both;
      @include media-map-up(min-height, 140px, 140px, 140px, 200px, 200px);
      > a {
        @extend .row;
        text-decoration: none;
        position: relative;
        img {
          @include media-breakpoint-up(xs) {
            width: 300px;
          }
          @include media-breakpoint-up(md) {
            @include make-col(3);
            @include make-col-offset(0);
            position: absolute;
          }
        }
        h2 {
          @include media-breakpoint-up(xs) {
            @include make-col(12);
            @include make-col-offset(0);
          }
          @include media-breakpoint-up(md) {
            @include make-col(9);
            @include make-col-offset(3);
          }
          @include media-map-up(font-size, 2rem, 2rem, 2rem, 2rem, 2rem);
          line-height: 1.2em;
          color: $color-one-lead;
        }
        p {
          text-align: justify;
          @include media-breakpoint-up(xs) {
            @include make-col(12);
            @include make-col-offset(0);
          }
          @include media-breakpoint-up(md) {
            @include make-col(9);
            @include make-col-offset(3);
          }
          margin-bottom: 0;
          color: #000;
        }
        &:hover {
          
        }
      }
      .article-info {
        @include make-col-ready();
        @include make-col(9);
        @include make-col-offset(3);
        color: #ccc;
        font-style: italic;
        font-size: 0.9em;
        text-align: right;
        p {
          display: inline-block;
          margin: 0;
        }
        .published-on {
        }
        .modified-on {
        }
        .authors {
        }
      }
    }
  }
}
