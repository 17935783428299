footer {
  position: absolute;
  bottom: 1px;
  left: 0;
  width: 100%;
  height: $footer-height;
  padding: 0;
  border-width: 0;
  border-style: solid;
  border-color: #aaa;
  background-color: $primary;
  color: $body-bg;
  @include make-container();
  > div {
    @extend .container-lg;
    > div {
      @include make-row();
      p {
        line-height: $footer-height;
        font-size: 1rem;
        margin: 0 auto;
        font-family: $font-title;
        @include make-col-ready();
        @include media-breakpoint-up(lg) { @include make-col(6); }
	a {
	  color: #fff;
    &:hover {
      
    }
	}
	&.contacts {
	  text-align: right;
	}
      }
    }
  }
}
