/* Features. */
$enable-transitions: true;
$debug: false;

/* Fonts. */
$font-standard: 'opensansregular', sans-serif;
$font-title: 'quicksand', sans-serif;
$font-subtitle: 'quicksand', sans-serif;
$font-code: monospace;

/* Sensitive sizes. */
$header-height: 50px;
$footer-height: 60px;
